const $ = function (selector, context) {
	context = context || document;
	return context.querySelector(selector);
};

const $$ = function (selector, context) {
	context = context || document;
	return Array.from(context.querySelectorAll(selector));
};

const $ready = function (context, callback, isVoid) {
	if (typeof context === "function" && !callback) {
		callback = context;
		context = undefined;
	}

	context = context || document;

	if (callback) {
		if (context.readyState !== "loading") {
			callback();
		} else {
            context.addEventListener("DOMContentLoaded", callback, { once: true });
		}
	}

	if (!isVoid) {
		return new Promise(function (resolve) {
			$ready(context, resolve, true);
		});
	}
};

let elDirectives = [];
const fireDirectives = function () {
	elDirectives.forEach(function (fn) {
		fn.call();
	});
};

// scroll breaks

elDirectives.push(() => {
	$$("[data-scroll-break]").forEach(function (el) {
		var scrollBreaks = el.dataset.scrollBreak.split(",");
		scrollBreaks.forEach(function (br) {
			el.classList.add("scroll-unbroken-" + br);
		});

		var addScrollListener = function (scrollbreak) {
			var sbClass = "scroll-unbroken-" + scrollbreak;

			var thisHandler = function (evt) {
				if (!el.classList.contains(sbClass)) return; // should cancel itself really....???
				if (window.scrollY >= scrollbreak) {
					window.requestAnimationFrame(function () {
						el.classList.remove(sbClass);
						// window.scrollTo(window.scrollX,0);

						scrollBreaks.shift();
						if (scrollBreaks[0]) addScrollListener(scrollBreaks[0]);

						// window.removeEventListener(thisHandler);
					});
				}
			};

			window.addEventListener("scroll", thisHandler);
		};

		addScrollListener(scrollBreaks[0]);

		el.removeAttribute("data-scroll-break"); // fire once only
	});

	$$("[data-sort-select]").forEach((el) => {
		let sortTarget = el.dataset.sortSelect;
		if (sortTarget == "") {
			console.warn("data-sort-select attribute has no sort target", el);
			return;
		}
		el.dataset.sortBy = "";
		let selectClick = (evt) => {
			evt.stopPropagation();
			let activeButton = $(".active", el) || null;
			if (evt.currentTarget == activeButton) {
				activeButton.classList.remove("active");
				el.dataset.sortBy = "";
			} else {
				$$("button", el).forEach((node) => node.classList.remove("active"));
				evt.currentTarget.classList.add("active");
				el.dataset.sortBy =
					$.value(evt, "currentTarget", "dataset", "sortValue") || "";
			}
			el.dispatchEvent(sortEvent);
			return;
		};
		$$("button", el).forEach((butt) =>
			butt.addEventListener("click", selectClick)
		);

		el.removeAttribute("data-sort-select"); // fire once only
	});

    // email obfuscation
    // add data-hidden-email to an anchor tag with a href attribute 
    // that has the email address in the format: email-at-domain-com
    $$("[data-hidden-email]").forEach((el) => {
		const business = function(){
			el.textContent = el.textContent.replace('~at~', '@')
			el.addEventListener('mouseover', (evt) => {
				let toGo = el.getAttribute('href');
				toGo = toGo.replace('-at-', '@');
				toGo = toGo.replace('-', '.');
				el.setAttribute('href', `mailto:${toGo}`);
			},{ once: true });
		}
		setTimeout(business, 800);
    });

});

fireDirectives();

const readyBusiness = (evt) => {
	// mobile nav button

	let mobileNav = $(".nav-mobile-button");
	if (mobileNav) {
		mobileNav.addEventListener("click", (evt) => {
			evt.stopPropagation();
			if (!$(".nav").classList.contains("active")) {
				$(".nav").classList.add("active");
				evt.currentTarget.classList.add("active");
			} else {
				$(".nav").classList.remove("active");
				evt.currentTarget.classList.remove("active");
			}
		});
		window.addEventListener("scroll", (evt) => {
			window.setTimeout(() => {
				if (($(".nav") || {}).classList) $(".nav").classList.remove("active");
				if (($(".nav-mobile-button") || {}).classList)
					$(".nav-mobile-button").classList.remove("active");
			}, 200);
		});
	}

	// new window for all documents

	$$('a[href$=".pdf"]').forEach((anc) => {
		anc.setAttribute("target", "_blank");
		return;
	});

	// general markup changes

	let generalContent;
	if ((generalContent = $(".content"))) {
		// markup paras

		$$("p", generalContent).forEach(function (para) {
			// markup images

			let images = $$("img", para);
			if (images.length == 1 && para.textContent.length < 1) {
				para.classList.add("sole-image");
			} else if (para.textContent.length < 1) {
				para.classList.add("multi-image");
			} else {
				para.classList.add("para-image");
				// images to top of para
				images.forEach(function (imgEl) {
					imgEl.parentNode.prepend(imgEl);
				});
			}

			// markup sole links

			if ($$("a", para).length == para.childNodes.length) {
				let anchors = $$("a", para);
				anchors.forEach(function (anchor) {
					anchor.parentNode.classList.add("has-sole-link");
					if (anchor.innerText.length < 40) {
						anchor.classList.add("sole-link", "btn", "btn-success");
					}
				});
			}
		});
	}
};

$ready().then(readyBusiness);
